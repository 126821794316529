<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Cancom Security Inc
        </h2>
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- username -->
              <b-form-group
                label="Username"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    tabindex="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <label for="login-password">Password</label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      tabindex="2"
                    />
                    <b-input-group-append
                      is-text
                    >
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="d-flex justify-content-between">
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid && !preventDoubleClick"
                tabindex="3"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
          <span class="d-none d-sm-inline-block mt-1">v{{ appVersion }}</span>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
import { Auth, API } from 'aws-amplify'

import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { refreshPageMixin } from '@/components/mixins/refresh-page.mixin'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getUserAbilities from '@/auth/abilityDefinitions'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, refreshPageMixin],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/logo/logo-large.png'),
      preventDoubleClick: false,

      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-large.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.appVersion = this.currentVersion
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.preventDoubleClick = true
          Auth.signIn(this.userEmail, this.password)
            .then(response => {
              // console.log(response)
              // Check to see if user needs to reset password
              if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // Reroute to New Password Screen
                this.$router.push({ name: 'auth-new-password', params: { theUser: response } })
              } else {
                // Get the current users data
                const apiName = 'APIGateway'
                const path = '/user'
                const myInit = { response: true }
                API.get(apiName, path, myInit)
                  .then(response1 => {
                    console.log(response1)
                    // const tempData = '{"userData": {"username": "johndoe","fullName": "John Doe","first_name": "John","last_name": "Doe",
                    // "avatar": "/img/13-small.d796bffd.png","email": "admin@demo.com","group": "Management","Is_active": true,"Is_onboarding": false,
                    // "phone": "123-123-1234","security_licence": "sdfsdf","authorized_clients": [ { "w": "x", "y": "z" } ]}}'
                    // const { userData } = JSON.parse(tempData)
                    // format the user data
                    const userData = {}
                    userData.username = response1.data.username
                    userData.fullName = `${response1.data.first_name} ${response1.data.last_name}`
                    userData.first_name = response1.data.first_name
                    userData.last_name = response1.data.last_name
                    userData.avatar = response1.data.avatar
                    userData.email = response1.data.email
                    userData.group = response1.data.cognito_group
                    userData.Is_active = response1.data.is_active
                    userData.Is_onboarding = response1.data.is_onboarding
                    userData.phone = response1.data.phone
                    userData.security_licence = response1.data.security_licence
                    userData.authorized_clients = response1.data.authorized_clients
                    userData.abilities = response1.data.abilities
                    userData.cell_phone_carrier = response1.data.cell_phone_carrier
                    userData.client_name = response1.data.client_name
                    userData.cognito_status = response1.data.cognito_status
                    userData.preferences = response1.data.preferences
                    // Get User abilities based on Role/Group
                    const userAbils = getUserAbilities(userData.abilities)
                    userData.ability = userAbils.abilities
                    // If the user is onboarding overwrite their abilities
                    if (userData.Is_onboarding) {
                      userData.ability = [{ subject: 'onboarding', action: 'update' }, { subject: 'Auth', action: 'read' }]
                    }
                    userData.role = userData.group
                    console.log(userData.ability)
                    this.$ability.update(userData.ability)
                    // Store User data in local storage
                    localStorage.setItem('userData', JSON.stringify(userData))
                    // Set the UIsettings in local storage
                    const UIsettings = JSON.parse(localStorage.getItem('UIsettings'))
                    if (UIsettings === null) {
                      console.log('UIsettings setup')
                      const tmp = {}
                      localStorage.setItem('UIsettings', JSON.stringify(tmp))
                    }
                    if (typeof userData.preferences !== 'undefined' && userData.preferences !== null) {
                      console.log('Got user preferences')
                      localStorage.setItem('UIsettings', JSON.stringify(userData.preferences))
                    }
                    // Route user
                    const nextRout = getHomeRouteForLoggedInUser(userData.group)
                    this.$router.replace(nextRout)
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Welcome ${userData.fullName || userData.username}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `You have successfully logged in as ${userData.group}. `,
                          },
                        })
                      })
                  })
                  .catch(error => {
                    console.log(error)
                    this.preventDoubleClick = false
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error fetching user information',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
            .catch(error => {
              console.log(error)
              this.preventDoubleClick = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

@import '@core/scss/vue/pages/page-auth.scss';
  .auth-inner {
    background-color: $theme-dark-card-bg;
  }
  .input-group-text {
    border-color: $theme-dark-input-border-color !important;
  }
</style>
